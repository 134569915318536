<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Reason List</b-breadcrumb-item> </b-breadcrumb
    ><br />
    <b-card title="Reason List">
      <b-row>
        <b-col cols="9">
          <b-button
            class="mb-2"
            style="margin-right: 15px"
            variant="primary"
            v-if="permission.add"
            @click="addReason()"
          >
            Add New Reason
          </b-button>
        </b-col>
      </b-row>
      <b-tabs align="end">
        <b-tab :title="titleTab(0)" active>
          <b-table
            :fields="fields"
            :items="pauseJobReason"
            responsive="sm"
            hover
            head-variant="dark"
            outlined
            small
            stacked="md"
          >
            <template #cell(No)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(Reason)="data">
              {{ data.item.Reason }}
            </template>

            <template #cell(Status)="data">
              {{ data.item.Status }}
            </template>

            <template #cell(Action)="data">
              <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item v-if="permission.edit">
                  <b-button
                    variant="primary"
                    size="sm"
                    style="margin-right:10px"
                    @click="edit(data.item.Reason, data.index, titleTab(0), data.item.Recoil, data.item.Status)"
                    ><feather-icon size="1x" icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <!-- <b-dropdown-item v-if="permission.delete">
                  <b-button
                    variant="danger"
                    size="sm"
                    style="margin-right:10px"
                    @click="
                      deleteReason(data.item.Reason, data.index, titleTab(0))
                    "
                    ><feather-icon size="1x" icon="Trash2Icon"
                  /></b-button>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
        <b-tab :title="titleTab(1)">
          <b-table
            :fields="fields"
            :items="machineStopReason"
            responsive="sm"
            hover
            head-variant="dark"
            outlined
            small
            stacked="md"
          >
            <template #cell(No)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(Reason)="data">
              {{ data.item.Reason }}
            </template>

            <template #cell(Status)="data">
              {{ data.item.Status }}
            </template>

            <template #cell(Action)="data">
              <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    variant="primary"
                    size="sm"
                    style="margin-right:10px"
                    @click="edit(data.item.Reason, data.index, titleTab(1), data.item.Recoil, data.item.Status)"
                    ><feather-icon size="1x" icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <!-- <b-dropdown-item>
                  <b-button
                    variant="danger"
                    size="sm"
                    style="margin-right:10px"
                    @click="
                      deleteReason(data.item.Reason, data.index, titleTab(1))
                    "
                    ><feather-icon size="1x" icon="Trash2Icon"
                  /></b-button>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
        <b-tab :title="titleTab(2)"
          ><b-table
            :fields="fields"
            :items="drumPickerReason"
            responsive="sm"
            hover
            head-variant="dark"
            outlined
            small
            stacked="md"
          >
            <template #cell(No)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(Reason)="data">
              {{ data.item.Reason }}
            </template>

            <template #cell(Status)="data">
              {{ data.item.Status }}
            </template>

            <template #cell(Action)="data">
              <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    variant="primary"
                    size="sm"
                    style="margin-right:10px"
                    @click="edit(data.item.Reason, data.index, titleTab(2), data.item.Recoil, data.item.Status)"
                    ><feather-icon size="1x" icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <!-- <b-dropdown-item>
                  <b-button
                    variant="danger"
                    size="sm"
                    style="margin-right:10px"
                    @click="
                      deleteReason(data.item.Reason, data.index, titleTab(2))
                    "
                    ><feather-icon size="1x" icon="Trash2Icon"
                  /></b-button>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
        <b-tab :title="titleTab(3)"
          ><b-table
            :fields="fields"
            :items="drumRejectReason"
            responsive="sm"
            hover
            head-variant="dark"
            outlined
            small
            stacked="md"
          >
            <template #cell(No)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(Reason)="data">
              {{ data.item.Reason }}
            </template>

            <template #cell(Status)="data">
              {{ data.item.Status }}
            </template>

            <template #cell(Action)="data">
              <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    variant="primary"
                    size="sm"
                    style="margin-right:10px"
                    @click="edit(data.item.Reason, data.index, titleTab(3), data.item.Recoil, data.item.Status)"
                    ><feather-icon size="1x" icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <!-- <b-dropdown-item>
                  <b-button
                    variant="danger"
                    size="sm"
                    style="margin-right:10px"
                    @click="
                      deleteReason(data.item.Reason, data.index, titleTab(2))
                    "
                    ><feather-icon size="1x" icon="Trash2Icon"
                  /></b-button>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal id="modal-edit" 
    v-model="showEditModal" 
    title="Edit Reason">
      <label for="selectOption">Status</label>
      <b-form-select
        id="selectOption"
        v-model="editModal.status"
        :options="activeInactive"
      >
      </b-form-select>

      <label for="textarea-default">Content</label>
      <b-form-textarea
        id="textarea-default"
        v-model="editModal.content"
      ></b-form-textarea>
      <b-form-checkbox
      v-if="showEditRecoilCheckbox"
      id="checkbox-recoil-machine-stop-edit"
      v-model="statusRecoilEdit"
      name="checkbox-recoil-machine-stop-edit"
      value="true"
      unchecked-value="false"
    >
      included Recoil
    </b-form-checkbox>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="success"
          @click="
            updateReason(
              editModal.content,
              editModal.index,
              editModal.reasonName,
              editModal.status
            ),
              ok()
          "
        >
          Update
        </b-button>
        <b-button size="sm" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-delete"
      v-model="showDeleteModal"
      title="Warning"
      ok-title="delte"
    >
      <b>do you want to delete this reason?</b>
      <template #modal-footer="{ ok, cancel}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="danger"
          @click="
            updateDeleteModal(deleteModal.index, deleteModal.reasonName), ok()
          "
        >
          Delete
        </b-button>
        <b-button size="sm" @click="cancel()">
          cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-add-reason"
      v-model="showAddReasonModal"
      title="Add New Reason"
    >
      <b-form-select
        id="selectOption"
        v-model="reasonDefault"
        :options="reasonName"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- Please select a Reason List --</b-form-select-option
          >
        </template>
      </b-form-select>

      <label for="selectOption" class="mt-1">Status:</label>
      <b-form-select
        id="selectOption"
        v-model="selectedStatusnew"
        :options="activeInactive"
      >
      </b-form-select>

      <label for="content-area" class="mt-2">Content</label>
      <b-form-textarea
        id="content-area"
        v-model="addModal.content"
        placeholder="Add New Reason"
        required
      >
      </b-form-textarea>

      <b-form-checkbox
      v-if="showRecoilCheckbox"
      id="checkbox-recoil-machine-stop"
      v-model="statusRecoil"
      name="checkbox-recoil-machine-stop"
      value="true"
      unchecked-value="false"
    >
      included Recoil
    </b-form-checkbox>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          @click="updateAddNewReason(), ok()"
        >
          add reason
        </b-button>
        <b-button size="sm" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BTable } from "bootstrap-vue";
import { mapActions } from "vuex";
import { userAccess } from "@/utils/utils.js";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BTable,
  },

  data() {
    return {
      dataReasonList: "",
      dataReasonListIndex: "",
      pauseJobReason: [],
      machineStopReason: [],
      drumPickerReason: [],
      drumRejectReason: [],
      reasonName: "",
      reasonDefault: null,
      tabIndex: 0,
      showRecoilCheckbox: false,
      showEditRecoilCheckbox:false,
      statusRecoil:"",
      statusRecoilEdit:"",
      showEditModal: false,
      selectedStatusnew: "Active",
      activeInactive: ["Active", "Inactive"],
      fields: ["No", "Reason", "Status", "Action"],
      editModal: {  
        id: "edit-reason-modal",
        content: "",
        status: "",
        index: "",
        reasonName: "",
      },

      showDeleteModal: false,
      deleteModal: {
        id: "delete-reason-modal",
        content: "",
        index: "",
        reasonName: "",
      },

      showAddReasonModal: false,
      addModal: {
        id: "add-reason-modal",
        content: "",
      },
    };
  },
  methods: {
    ...mapActions({}),
    refresh() {
      this.$store.dispatch("plant/getReasonList").then((res) => {
        var allData = this.$store.getters["plant/getReasonList"];
        this.dataReasonList = allData;
        this.syncReason(allData);
      });
    },
    syncReason(allData) {
      this.pauseJobReason = allData[0].reasonList.map((x) => {
        return { Reason: x.reason, Status: x.status };
      });
      this.machineStopReason = allData[1].reasonList.map((x) => {
        return { Reason: x.reason, Recoil: x.recoil, Status: x.status };
      });
      this.drumPickerReason = allData[2].reasonList.map((x) => {
        return { Reason: x.reason, Status: x.status };
      });
      this.drumRejectReason = allData[3].reasonList.map((x) => {
        return { Reason: x.reason, Status: x.status };
      });
      this.reasonName = allData.map((x) => x.reasonName);
    },
    titleTab(index) {
      if (index == 0) {
        return this.reasonName[0];
      } else if (index == 1) {
        return this.reasonName[1];
      } else if (index == 2) {
        return this.reasonName[2];
      } else if (index == 3) {
        return this.reasonName[3];
      }
    },

    edit(data, index, title, recoil, status) {
      if(recoil === undefined) {
        this.statusRecoilEdit = "false"
      }else{
        this.statusRecoilEdit = "true"
      }
      this.editModal.content = data;
      this.editModal.index = index;
      this.editModal.reasonName = title;
      this.showEditModal = true;
      this.editModal.status = status;
      if(title == "Machine Stop"){
        this.showEditRecoilCheckbox = true
      }else{
        this.showEditRecoilCheckbox = false
      }
    },

    deleteReason(data, index, title) {
      this.showDeleteModal = true;
      this.deleteModal.index = index;
      this.deleteModal.reasonName = title;
    },

    addReason() {
      this.showAddReasonModal = true;
    },

    updateReason(data, index, reason, status) {
      var currData = this.dataReasonList.filter((x) => x.reasonName == reason);
      var currReason = currData[0].reasonList;
      currReason[index].reason = data
      currReason[index].status = status
      if(this.statusRecoilEdit == "true"){
        currReason[index].recoil = true
      }else if(this.statusRecoilEdit == "false"){
        delete currReason[index].recoil
      }

      var payload = {
        id: currData[0].id,
        reasonList: currReason,
      };
      this.$store
        .dispatch("plant/updateReasonList", payload)
        .then((res) => {
          this.refresh();
          this.$bvToast.toast("Reason List Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("something Wrong", {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
    },
    //deleted a new reason
    updateDeleteModal(index, reason) {
      var currData = this.dataReasonList.filter((x) => x.reasonName == reason);

      var oldreason = currData[0].reasonList;

      const removedArray = oldreason.filter((x) => x !== oldreason[index]);

      var payload = {
        id: currData[0].id,
        reasonList: removedArray,
      };

      this.$store
        .dispatch("plant/updateReasonList", payload)
        .then((res) => {
          this.refresh();
          this.$bvToast.toast("Reason List Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("something Wrong", {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
    },
    //add new reason
    updateAddNewReason() {
      
      var currData = this.dataReasonList.filter(
        (x) => x.reasonName == this.reasonDefault
      );
      var oldreason = currData[0].reasonList;
      if (this.statusRecoil == "true") {
        var newPayload = {
          reason: this.addModal.content,
          status: this.selectedStatusnew,
          recoil: true,
        };
      } else {
        var newPayload = {
          reason: this.addModal.content,
          status: this.selectedStatusnew
        };
      }
      oldreason.push(newPayload);
      var payload = {
        id: currData[0].id,
        reasonList: oldreason,
      };
      this.$store
        .dispatch("plant/updateReasonList", payload)
        .then((res) => {
          this.refresh();
          this.reasonDefault = null
          this.selectedStatusnew = "Active"
          this.addModal.content = ""
          this.$bvToast.toast("Reason List Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("something Wrong", {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
    },
  },
  computed: {
    permission() {
      return userAccess("Reason List");
    },
  },
  watch: {
    // show showRecoilCheckbox if tab Machine Stop clicked
    reasonDefault(value) {
      if(value == "Machine Stop"){
        this.showRecoilCheckbox = true
      }else{
        this.showRecoilCheckbox = false
      }
    }
  },
  mounted() {
    this.$store
      .dispatch("plant/getReasonList")
      .then((res) => {
        var allData = this.$store.getters["plant/getReasonList"];
        this.dataReasonList = allData;
        if (allData.length == 4) {
          this.syncReason(allData);
        } else {
          var payload = {};
          this.$store.dispatch("plant/addNewReasonList", payload);
          location.reload();
        }
      })
      .catch((err) => console.log({ err }));
  },
};
</script>

<style></style>
